<template>
  <v-app id="inspire">
    <v-navigation-drawer mobile-breakpoint="1024" app width="74" v-model="drawer" class="" color="">
      <div>
        <div class="cursor d-flex justify-center align-center sidebar_head" @click="$router.push('/dashboard').catch(() => {})">
          <v-img :max-width="$store.state.brokerName == 'Aliceblue' ? 35 : 30" :src="($store.state.brokerLogo)" alt="logo"></v-img>
        </div>

        <v-tabs class="sidebar mt-3" vertical v-model="selectedItem">
          <!-- @click="changeRoute(item)" -->
          <v-tab class="sidebarTab" v-for="(item, i) in menuList" @click="changeRoute(item)" :key="i">
            <div class="menu d-flex align-center flex-column" :id="`${i}_${item.text}`">
              <v-img max-width="50%" :src="getImgUrl(item.svg)" class="unactive_img mb-1" :alt="item.text"></v-img>
              <!-- <img  /> -->
              <!-- <img :src="getImgUrl(item.svg+'_dark')" v-if="selectedItem != i" class="darkThemeImg" /> -->
              <div class="fsize11 primaryColor font-weight-bold text-overflow">{{ item.text }}</div>
              <div class="fsize11 primaryColor font-weight-bold text-overflow">{{item.subText}}</div>
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </v-navigation-drawer>

    <v-app-bar flat tile class="border-bottom-light bg_white" app dense height="56px">
      <v-app-bar-nav-icon v-if="$store.state.isMobileView" @click="drawer = !drawer" class="ma-0"></v-app-bar-nav-icon>
      <!-- <div class="ml-3 primaryColor">{{`${$store.state.brokerName} - Admin Dashboard`}}</div> -->
      <v-spacer></v-spacer>
      <div class="text-right d-flex align-center">
        <div class="d-flex align-center fsize14">
          <a class="mx-2" href="https://v2api.aliceblueonline.com/postman" target="_blank" id="postman_scrips">Postman Scrips</a>
          <a class="mx-6" href="https://v2api.aliceblueonline.com/" id="api_docs" target="_blank">API Docs</a>
        </div>
        <v-menu max-width="190" right bottom :offset-y="true" transition="slide-y-transition" :position-y="300" rounded>
          <template v-slot:activator="{ on, attrs }">
            <div class="align-center">
              <span v-bind="attrs" v-on="on" class="secondaryColor fsize14 font-weight-bold mr-2 text-capitalize" id="developer_Id">{{userName}}</span>
            </div>
            <!-- <v-icon >mdi-dots-vertical-circle-outline</v-icon> -->
          </template>
          <v-list class="py-0">
            <v-list-item :id="`${idx}_${i.text}`" v-for="(i,idx) in dropdownList" :key="idx" class="body-2 header_menu" @click="navigatePage(i)">
              <!-- <v-list-item-icon>
                <v-icon small>mdi-logout
                </v-icon>
              </v-list-item-icon> -->
              <v-list-item-content>
                <span class="primaryColor font-weight-bold fsize14">{{i.text}}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

      </div>
    </v-app-bar>

    <v-main class="main_block">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </v-main>

    <v-dialog v-model="logoutDialog" width="370" overlay-color="white">
      <v-card class="pa-4 cardBoxShadow rounded-lg" height="130">
        <div class="secondaryColor fsize14 d-flex justify-space-between">
          <div>Are you sure want to logout ?</div>
          <div>
            <v-icon id="cancel_icon" @click="logoutDialog = false" color="light-blue">mdi-close-circle-outline</v-icon>
          </div>
        </div>
        <div class="d-flex justify-end mt-10">
          <v-btn height="32" id="logout_btn" width="86" @click="logout()" depressed outlined text color="light-blue" class="text-capitalize white--text fsize12 mr-2">
            Confirm
          </v-btn>
          <v-btn height="32" id="cancel_btn" width="86" @click="logoutDialog = false" depressed color="black" outlined text class="text-capitalize fsize12">
            Cancel
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    drawer: true,
    logoutDialog: false,
    selectedItem: 0,
    menuList: [
      // {
      //   text: "Dashboard",
      //   subText: "",
      //   svg: "home",
      //   route: "dashboard",
      // },
      {
        text: "My Apps",
        subText: "",
        svg: "user",
        route: "apps",
      },
      // {
      //   text: "Guide",
      //   subText: "",
      //   svg: "orders",
      //   route: "guide",
      // },
    ],
    dropdownList: [
      // {
      //   text: "Dashboard",
      //   route: "dashboard",
      // },
      // {
      //   text: "Content",
      //   route: "content",
      // },
      {
        text: "Logout",
        route: "logout",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      userName: "getUserId",
    }),
  },
  methods: {
    getImgUrl(img) {
      var images = require.context("../assets/menu/", false, /\.svg$/);
      return images("./" + img + ".svg");
    },
    navigatePage(val) {
      if (val.route == "logout") {
        this.logoutDialog = true;
      } else {
        this.$router.push(val.route).catch(() => {});
      }
    },
    changeRoute(val) {
      this.$router.push(val.route).catch(() => {});
    },
    logout() {
       this.$store.commit("setUserId", null);
       this.$store.commit("setSessionId",null);
      localStorage.clear();
      this.$router.push({path: "/", query: {logout: "true"}}).catch(() => {});
      // this.$store.dispatch('auth/logout')
    },
  },
};
</script>
<style scoped>
.sidebar {
  width: 74px !important;
}
.v-navigation-drawer__content {
  overflow: clip !important;
}
.border-lft {
  border-left: 1px solid #ededed;
}
::v-deep.v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 0px !important;
}
.hovershow:hover .indexChart {
  display: block !important;
}
.sidebar .v-tab {
  min-width: 64px !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  color: #282828 !important;
  padding: 8px 0px !important;
  letter-spacing: normal !important;
}
.sidebar .v-tab--active {
  color: #0065be !important;
}
.sidebar .v-tab--active {
  filter: invert(50%) sepia(85%) saturate(5176%) hue-rotate(192deg)
    brightness(102%) contrast(106%) !important;
}
.sidebar .v-tab:hover {
  filter: invert(50%) sepia(85%) saturate(5176%) hue-rotate(192deg)
    brightness(102%) contrast(106%) !important;
}
.theme--light .darkThemeImg {
  display: contents !important;
}
.theme--dark .sidebar .v-tab .unactive_img {
  display: contents !important;
}
.theme--dark .sidebar .v-tab--active .unactive_img {
  display: inline-block !important;
}
.theme--dark .sidebar .v-tab {
  height: 68px !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  color: #ffffff !important;
  padding: 8px 0px !important;
  letter-spacing: normal !important;
}
.theme--light .sidebar .v-tab {
  height: 68px !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  color: #282828 !important;
  padding: 8px 0px !important;
  letter-spacing: normal !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background: #363636 !important;
}
.theme--dark.v-navigation-drawer {
  background-color: #1e1e1e !important;
}
.theme--dark.v-app-bar.v-toolbar {
  background-color: #1e1e1e !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 24px !important;
}
.text-overflow {
  text-overflow: inherit !important;
  overflow: hidden !important;
  width: 60px;
}
.main_block {
  background-color: #f7f7f7;
}
</style>